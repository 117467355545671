import { ErrorMessage, type StreamMessage } from './APIModel';

export async function* readStream(response: Response): AsyncGenerator<StreamMessage, void> {
	if (!response.ok) {
		// covered errors from this doc
		// https://hello.atlassian.net/wiki/spaces/CONVAI/pages/3420210503/Error+message+formats+-+assistance-service
		const responseText = await response.text();
		switch (response.status) {
			case 201: {
				yield {
					type: 'ERROR',
					message: {
						content: `No answer was generated by Open AI. Raw error details: ${responseText}`,
						message_template: ErrorMessage.NO_ANSWER,
						status_code: response.status,
					},
				};
				return;
			}
			case 403: {
				yield {
					type: 'ERROR',
					message: {
						content: 'AI feature is not enabled for cloud id by site admin',
						message_template: ErrorMessage.AI_DISABLED,
						status_code: response.status,
					},
				};
				return;
			}
			case 429: {
				yield {
					type: 'ERROR',
					message: {
						content: `RATE_LIMIT or ACCEPTABLE_USE_VIOLATIONS or OPENAI_RATE_LIMIT_USER_ABUSE. Raw error details: ${responseText}`,
						message_template: ErrorMessage.RATE_LIMIT,
						status_code: response.status,
					},
				};
				return;
			}
			case 400: {
				yield {
					type: 'ERROR',
					message: {
						content: `No agent was found that matches the request. Raw error details: ${responseText}`,
						message_template: ErrorMessage.NO_AGENT,
						status_code: response.status,
					},
				};
				return;
			}
			case 500: {
				yield {
					type: 'ERROR',
					message: {
						content: `INTERNAL_SERVER_ERROR. Raw error details: ${responseText}`,
						message_template: ErrorMessage.UNEXPECTED,
						status_code: response.status,
					},
				};
				return;
			}
		}
	}

	if (!response.body) {
		const responseText = await response.text();
		yield {
			type: 'ERROR',
			message: {
				content: `response.body is missing. Raw error details: ${responseText}`,
				message_template: ErrorMessage.NETWORK_ERROR,
				status_code: response.status,
			},
		};
		return;
	}

	try {
		const reader = response.body.getReader();
		const decoder = new TextDecoder('utf-8');
		let buffer = '';
		let done = false;

		while (!done) {
			const { value, done: doneReading } = await reader.read();
			const decodedValue = decoder.decode(value);
			done = doneReading;

			buffer = `${buffer}${decodedValue}`;
			// Split the buffer by line breaks
			const lines = buffer.split('\n');
			// Process all complete lines, except for the last one (which might be incomplete)
			while (lines.length > 1) {
				const line = lines.shift()!;
				const parsedData = JSON.parse(line);

				yield parsedData;
			}
			// Keep the last (potentially incomplete) line in the buffer
			buffer = lines[0];
		}

		reader.releaseLock();

		return;
	} catch (parsingError: unknown) {
		let errorText = '';
		if (typeof parsingError === 'string') {
			errorText = parsingError.toUpperCase();
		} else if (parsingError instanceof Error) {
			errorText = parsingError.message;
		}
		/// https://ops.internal.atlassian.com/jira/projects/HOT/queues/issue/HOT-111586
		errorText += `[readStream:logs] ${response.body.locked}], streaming status: ${response.status}`;
		yield {
			type: 'ERROR',
			message: {
				content: errorText,
				message_template: ErrorMessage.UNEXPECTED,
			},
		};
		return;
	}
}
